import React, { memo } from "react";
import Template from "./Template";
import { clsx } from "keycloakify/lib/tools/clsx";

const LoginConfigTotp = memo((props) => {
    const { kcContext, i18n, doFetchDefaultThemeResources = true, ...kcProps } = props;

    const { url, isAppInitiatedAction, totp, mode, messagesPerField } = kcContext;

    const { msg, msgStr } = i18n;

    const algToKeyUriAlg = {
        "HmacSHA1": "SHA1",
        "HmacSHA256": "SHA256",
        "HmacSHA512": "SHA512"
    };

    return (
        <Template
            {...{ kcContext, i18n, doFetchDefaultThemeResources, ...kcProps }}
            headerNode={msg("loginTotpTitle")}
            formNode={
                <>
                    <ol id="kc-totp-settings" className="!px-0">
                        {totp.policy.supportedApplications ? (
                            <li>
                                <p>{msg("loginTotpStep1")}</p>

                                <ul id="kc-totp-supported-apps">
                                    {totp.policy.supportedApplications.map(app => (
                                        <li>{app}</li>
                                    ))}
                                </ul>
                            </li>) :
                            <></>
                        }

                        {mode && mode == "manual" ? (
                            <>
                                <li>
                                    <p>{msg("loginTotpManualStep2")}</p>
                                    <p>
                                        <span id="kc-totp-secret-key">{totp.totpSecretEncoded}</span>
                                    </p>
                                    <p>
                                        <a href={totp.qrUrl} id="mode-barcode">
                                            {msg("loginTotpScanBarcode")}
                                        </a>
                                    </p>
                                </li>
                                <li>
                                    <p>{msg("loginTotpManualStep3")}</p>
                                    <p>
                                        <ul>
                                            <li id="kc-totp-type">
                                                {msg("loginTotpType")}: {msg(`loginTotp.${totp.policy.type}`)}
                                            </li>
                                            <li id="kc-totp-algorithm">
                                                {msg("loginTotpAlgorithm")}: {algToKeyUriAlg?.[totp.policy.algorithm] ?? totp.policy.algorithm}
                                            </li>
                                            <li id="kc-totp-digits">
                                                {msg("loginTotpDigits")}: {totp.policy.digits}
                                            </li>
                                            {totp.policy.type === "totp" ? (
                                                <li id="kc-totp-period">
                                                    {msg("loginTotpInterval")}: {totp.policy.period}
                                                </li>
                                            ) : (
                                                <li id="kc-totp-counter">
                                                    {msg("loginTotpCounter")}: {totp.policy.initialCounter}
                                                </li>
                                            )}
                                        </ul>
                                    </p>
                                </li>
                            </>
                        ) : (
                            <li>
                                <p>{msg("loginTotpStep2")}</p>
                                <img className="m-auto" id="kc-totp-secret-qr-code" src={`data:image/png;base64, ${totp.totpSecretQrCode}`} alt="Figure: Barcode" />
                                <p>
                                    <a href={totp.manualUrl} id="mode-manual">
                                        {msg("loginTotpUnableToScan")}
                                    </a>
                                </p>
                                <hr className="mt-5"/>
                            </li>
                        )}
                        <li>
                            <br/>
                            <p>{msg("loginTotpStep3")}</p>
                            <br/>
                            <p>{msg("loginTotpStep3DeviceName")}</p>
                            <br/>
                        </li>
                    </ol>

                    <form action={url.loginAction} className={clsx(kcProps.kcFormClass)} id="kc-totp-settings-form" method="post">
                        <div className={clsx(kcProps.kcFormGroupClass)}>
                            <div className={clsx(kcProps.kcInputWrapperClass)}>


                                <div className="w-[320px]">
                                    <input
                                        tabIndex={1}
                                        type="text"
                                        id="totp"
                                        name="totp"
                                        autoComplete="off"
                                        placeholder={msgStr("authenticatorCode")}
                                        className="inline w-[300px] h-[44px] px-3 outline-0 border-0 ring-1 ring-neutral text-[13px] text-default focus:ring-primary"
                                        aria-invalid={messagesPerField.existsError("totp")}
                                    />
                                </div>

                                {messagesPerField.existsError("totp") && (
                                    <span id="input-error-otp-code" className={clsx(kcProps.kcInputErrorMessageClass)} aria-live="polite">
                                        {messagesPerField.get("totp")}
                                    </span>
                                )}
                            </div>
                            <input type="hidden" id="totpSecret" name="totpSecret" value={totp.totpSecret} />
                            {mode && <input type="hidden" id="mode" value={mode} />}
                        </div>

                        <div className={clsx(kcProps.kcFormGroupClass)}>
                            <div className={clsx(kcProps.kcInputWrapperClass)}>
                                <div className="w-[320px]">
                                    <input
                                        tabIndex={1}
                                        type="text"
                                        id="userLabel"
                                        name="userLabel"
                                        placeholder={msgStr("loginTotpDeviceName")}
                                        autoComplete="off"
                                        className="inline w-[300px] h-[44px] px-3 outline-0 border-0 ring-1 ring-neutral text-[13px] text-default focus:ring-primary"
                                        aria-invalid={messagesPerField.existsError("userLabel")}
                                    />
                                </div>
                                {messagesPerField.existsError("userLabel") && (
                                    <span id="input-error-otp-label" className={clsx(kcProps.kcInputErrorMessageClass)} aria-live="polite">
                                        {messagesPerField.get("userLabel")}
                                    </span>
                                )}
                            </div>
                        </div>

                        {isAppInitiatedAction ? (
                            <>
                                <input
                                    type="submit"
                                    className="h-[44px] text-center w-1/2 bg-buttonPrimary border-buttonSecondary hover:bg-buttonSecondary p-[7px] text-white"
                                    id="saveTOTPBtn"
                                    value={msgStr("doSubmit")}
                                />
                                <button
                                    type="submit"
                                    className="h-[44px] text-center w-1/2 bg-white border-1 border-buttonPrimary hover:text-buttonSecondary p-[7px] text-buttonPrimary"
                                    id="cancelTOTPBtn"
                                    name="cancel-aia"
                                    value="true"
                                >
                                    {msg("doCancel")}
                                </button>
                            </>
                        ) : (
                            <input
                                type="submit"
                                className="h-[44px] text-center w-full bg-buttonPrimary border-buttonSecondary hover:bg-buttonSecondary p-[7px] text-white"
                                id="saveTOTPBtn"
                                value={msgStr("doSubmit")}
                            />
                        )}
                    </form>
                </>
            }
        />
    );
});

export default LoginConfigTotp;