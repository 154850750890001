import React, { memo } from "react";
import Template from "./Template";
import { clsx } from "keycloakify/lib/tools/clsx";
import { FaLock, FaUserAlt } from "react-icons/fa";

const LoginUpdatePassword = memo((props) => {
    const { kcContext, i18n, theme, doFetchDefaultThemeResources = true, ...kcProps } = props;

    const { url, isAppInitiatedAction, username } = kcContext;

    const { msg, msgStr } = i18n;

    return (
        <Template
            {...{ kcContext, i18n, doFetchDefaultThemeResources, ...kcProps }}
            headerNode={msg("updatePasswordTitle")}
            theme={props.theme}
            formNode={
                <form id="kc-passwd-update-form" className={clsx(kcProps.kcFormClass)} action={url.loginAction} method="post">
                    <div className="mt-[32px] mb-4">
                        <h1 id="kc-page-title" className="color-default text-[16px] text-default font-sans font-light">{msg("updatePasswordTitle")}</h1>
                    </div>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        value={username}
                        readOnly={true}
                        autoComplete="username"
                        style={{ display: "none" }}
                    />
                    <input type="password" id="password" name="password" autoComplete="current-password" style={{ display: "none" }} />

                    <div className="pt-2 pb-5 w-[320px]">
                        <input
                            type="password"
                            id="password-new"
                            name="password-new"
                            autoFocus
                            autoComplete="new-password"
                            className={"inline w-[300px] h-[44px] px-3  outline-0 border-0 ring-1 ring-neutral text-[13px] text-default focus:ring-primary"}
                            placeholder={msgStr("password")}
                        />
                        <FaLock className="z-1 text-[#e0e0e0] text-[20px] inline relative right-[30px]" />
                    </div>

                    <div className="pt-2 w-[320px]">
                        <input
                            type="password"
                            id="password-confirm"
                            name="password-confirm"
                            autoComplete="new-password"
                            className={"inline w-[300px] h-[44px] px-3  outline-0 border-0 ring-1 ring-neutral text-[13px] text-default focus:ring-primary"}
                            placeholder={msgStr("passwordConfirm")}
                        />
                        <FaLock className="z-1 text-[#e0e0e0] text-[20px] inline relative right-[30px]" />
                    </div>

                    <div>
                        {isAppInitiatedAction && (
                            <div id="kc-form-options" className={clsx(kcProps.kcFormOptionsClass)}>
                                <div className={clsx(kcProps.kcFormOptionsWrapperClass)}>
                                    <div className="checkbox">
                                        <label>
                                            <input type="checkbox" id="logout-sessions" name="logout-sessions" value="on" checked />
                                            {msgStr("logoutOtherSessions")}
                                        </label>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div id="kc-form-buttons" className="w-full border-[1px]">
                            {isAppInitiatedAction ? (
                                <>
                                    <input
                                        className={"h-[44px] text-center text-buttonText bg-buttonPrimary border-buttonSecondary hover:bg-buttonSecondary p-[7px] text-white"}
                                        type="submit"
                                        defaultValue={msgStr("doSubmit")}
                                    />
                                    <button
                                        className={"h-[44px] text-center w-full bg-white border-buttonSecondary hover:text-buttonSecondary hover:border-primary p-[7px] text-primary"}
                                        type="submit"
                                        name="cancel-aia"
                                        value="true"
                                    >
                                        {msg("doCancel")}
                                    </button>
                                </>
                            ) : (
                                <input
                                    className="h-[44px] text-center w-full hover:bg-buttonSecondary p-[7px] text-black"
                                    type="submit"
                                    value={msgStr("setNewPassword")}
                                />
                            )}
                        </div>
                    </div>
                </form>
            }
        />
    );
});

export default LoginUpdatePassword;