import React from "react"
import { clsx } from "keycloakify/lib/tools/clsx";
import Template from "./Template";

export default function LoginOtp(props) {
    const {
        kcContext,
        i18n,
        doFetchDefaultThemeResources = true,
        ...kcProps
    } = props


    const { otpLogin, url } = kcContext

    const { msg, msgStr } = i18n

    return (
        <Template
            {...{ kcContext, i18n, doFetchDefaultThemeResources, ...kcProps }}
            headerNode={msg("doLogIn")}
            formNode={
                <form
                    id="kc-otp-login-form"
                    className={clsx(kcProps.kcFormClass)}
                    action={url.loginAction}
                    method="post"
                >
                    {otpLogin.userOtpCredentials.length > 1 && (
                        <div>
                            <div className="">
                                <div className="">
                                    {otpLogin.userOtpCredentials.map(otpCredential => (
                                        <div
                                            key={otpCredential.id}
                                            className="border-neutral border-[1px] hover:border-b-primary text-default hover:border-b-lg hover:border-b-2 hover:border-neutral hover:border-l-[1px] hover:border-r-[1px] hover:border-t-[1px] 
                                            cursor-pointer h-24 text-center my-4 p-6 hover:text-black"
                                        >
                                            <input type="hidden" value="${otpCredential.id}" />
                                            <div className="m-auto h-full flex items-center justify-center flex-col">
                                                <h2 className="color-default text-[16px] ">
                                                    {otpCredential.userLabel}
                                                </h2>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>

                        </div>


                    )}
                    <div className={clsx(kcProps.kcFormGroupClass)}>
                        <div className={clsx(kcProps.kcInputWrapperClass)}>
                            <h1 id="kc-page-title" className="pt-4 pb-6 color-default text-[16px] text-default font-sans font-light">{msg("loginOtpOneTime")}:</h1>
                            <input
                                id="otp"
                                name="otp"
                                autoComplete="off"
                                type="text"
                                placeholder={msgStr("loginOtpOneTime")}
                                className="inline w-[300px] h-[44px] px-3 outline-0 border-0 ring-1 ring-neutral text-[13px] text-default focus:ring-primary"
                                autoFocus
                            />
                        </div>
                    </div>

                    <div className={clsx(kcProps.kcFormGroupClass)}>
                        <div
                            id="kc-form-buttons"
                            className={"!mt-0 " + clsx(kcProps.kcFormButtonsClass)} >
                            <input
                                className="h-[44px] text-center w-full bg-buttonPrimary border-buttonSecondary hover:bg-buttonSecondary p-[7px] text-white"
                                name="login"
                                id="kc-login"
                                type="submit"
                                value={msgStr("doLogIn")}
                            />
                        </div>
                    </div>
                </form>
            }
        />
    )
}
