import { getKcContext } from "keycloakify";

export const { kcContext } = getKcContext({
    "mockPageId": "login.ftl",
    "mockData": [
        {
            "pageId": "login.ftl",
            "locale": {
                "currentLanguageTag": "de"
            },
            "registrationDisabled": true,
            "realm": {
                "rememberMe": false,
                "displayNameHtml": "green.screen (TEST)"
            },
            "social": {
                "displayInfo": true,
                "providers": [ {
                    "loginUrl": "google.de",
                    alias: "entra",
                    providerId: "123",
                    displayName: "Login mit Microsoft SSO"
                }]
            }
        },
        {
            "pageId": "login-reset-password.ftl",
            "locale": {
                "currentLanguageTag": "de"
            },
            "realm": {
                "displayNameHtml": "green.screen (TEST)"
            }
        },
        {
            "pageId": "login-update-password.ftl",
            "locale": {
                "currentLanguageTag": "de"
            },
            "realm": {
                "displayNameHtml": "green.screen (TEST)"
            }
        },
        {
            "pageId": "login-config-totp.ftl",
            "locale": {
                "currentLanguageTag": "de"
            },
            "realm": {
                "displayNameHtml": "green.screen (TEST)"
            },
            "isAppInitiatedAction": true
        }
    ]
});