import { useI18n as useI18nBase } from "keycloakify";

export function useI18n(props) {
    const { kcContext } = props;
    return useI18nBase({
        kcContext,
        "extraMessages": {
            "de": {
                "doLogIn": "Anmelden",
                "doForgotPassword": "Haben Sie Ihr Passwort vergessen?",
                "headSuccess": "Anmeldefehler",
                "emailForgotTitle" : "Passwort zurücksetzen",
                "backToLogin": "« zur Anmeldung",
                "agbSystemTemplate": "Nutzungsbedingungen",
                "dataPrivacyStatementSystemTemplate": "Datenschutzerklärung",
                "disclaimerSystemTemplate": "Disclaimer",
                "impressumSystemTemplate": "Impressum",
                "supportSystemTemplate": "Support",
                "msEntraNowAvailable": "green.screen funktioniert jetzt mit MS Entra. Zum Login bitte auf den Button klicken.",
                "hintUseUsernameAndPassword": "Falls Sie keine MS Entra ID haben, können Sie sich mit Ihrem Benutzernamen und Passwort anmelden.",
                "getHelp": "Bei Problemen mit Login bitte an green.screen@bertelsmann.de wenden.",
                "setNewPassword": "Neues Passwort anfordern"
            },
            "en": {
                "doLogIn": "Sign in",
                "alphanumericalCharsOnly": "Only alphanumerical characters",
                "gender": "Gender",
                "doForgotPassword": "I forgot my password",
                "agbSystemTemplate": "Terms of use",
                "dataPrivacyStatementSystemTemplate": "Privacy policy",
                "disclaimerSystemTemplate": "Disclaimer",
                "impressumSystemTemplate": "Legal notice",
                "supportSystemTemplate": "Support",
                "msEntraNowAvailable": "green.screen now works with MS Entra. Please click the button to log in.",
                "hintUseUsernameAndPassword": "If you do not have an MS Entra ID, you can log in with your username and password.",
                "getHelp": "For login issues, please contact green.screen@bertelsmann.de.",
                "setNewPassword": "Set new password"
            }
        }
    });
}