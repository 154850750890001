import { Suspense } from "react";
import KcAppBase, { defaultKcProps } from "keycloakify";
import { useI18n } from "./i18n";
import Login from "../Theme/Login";
import LoginResetPassword from "../Theme/LoginResetPassword";
import LoginUpdatePassword from "../Theme/LoginUpdatePassword";
import LoginConfigTotp from "../Theme/LoginConfigTotp";
import LoginOtp from "../Theme/LoginOtp";

export default function KcApp(props) {

    const kcContext = props.kcContext;

    const theme = props.theme;

    const i18n = useI18n({ kcContext });

    const setTheme = props.setTheme;

    if (i18n === null) {
        return null;
    }

    const newProps = {
        i18n,
        ...defaultKcProps
    };


    return (
        <Suspense>
            {(() => {
                switch (kcContext.pageId) {
                    case "login.ftl": return <Login {...{ kcContext, theme, setTheme, ...newProps }} />;
                    case "login-reset-password.ftl": return <LoginResetPassword {...{ kcContext, theme, ...newProps }} />;
                    case "login-update-password.ftl": return <LoginUpdatePassword {...{ kcContext, theme, ...newProps }} />;
                    case "login-config-totp.ftl": return <LoginConfigTotp {...{ kcContext, theme, ...newProps }} />;
                    case "login-otp.ftl": return <LoginOtp {...{ kcContext, theme, ...newProps }} />;
                    default: return <KcAppBase {...{ kcContext, ...newProps }} />;
                }
            })()}
        </Suspense>
    );

}