import React, { memo } from "react";
import Template from "./Template";
import { clsx } from "keycloakify/lib/tools/clsx";
import { FaUserAlt } from "react-icons/fa";


const LoginResetPassword = memo((props) => {
    const { kcContext, i18n, doFetchDefaultThemeResources = true, ...kcProps } = props;

    const { url, realm, auth } = kcContext;

    const { msg, msgStr } = i18n;

    return (
        <Template
            {...{ kcContext, i18n, doFetchDefaultThemeResources, ...kcProps }}
            displayMessage={false}
            headerNode={msg("emailForgotTitle")}
            theme={props.theme}
            formNode={
                <form id="kc-reset-password-form" className={"first-letter:" + clsx(kcProps.kcFormClass)} action={url.loginAction} method="post">
                    <div className="mt-16 mb-4">
                        <h1 id="kc-page-title" className="color-default text-[16px] text-default font-sans font-light">{msg("emailForgotTitle")}</h1>
                    </div>
                    <div className="pt-2 pb-5 w-[320px]">
                        <input
                            tabIndex={2}
                            id="username"
                            name="username"
                            className="inline w-[300px] h-[44px] px-3  outline-0 border-0 ring-1 ring-neutral text-[13px] text-default focus:ring-primary"
                            defaultValue={auth !== undefined && auth.showUsername ? auth.attemptedUsername : undefined}
                            placeholder={!realm.loginWithEmailAllowed
                                ? msgStr("username")
                                : !realm.registrationEmailAsUsername
                                ? msgStr("usernameOrEmail")
                                : msgStr("email")}
                        />
                        <FaUserAlt className="z-1 text-[#e0e0e0] text-[20px] inline relative right-[30px]" />
                    </div>
                    <div className={clsx(kcProps.kcFormGroupClass, kcProps.kcFormSettingClass)}>
                        <div id="kc-form-options">
                            <div className="text-default font-light text-[13px] font-sans">
                                <span>
                                    <a href={url.loginUrl}>{msg("backToLogin")}</a>
                                </span>
                            </div>
                        </div>

                        <div id="kc-form-buttons" className="!mt-[59px] w-full">
                            <input
                                className="h-[44px] text-center w-full bg-buttonPrimary border-buttonSecondary hover:bg-buttonSecondary p-[7px] text-white"
                                type="submit"
                                value={msgStr("doSubmit")}
                            />
                        </div>
                    </div>
                </form>
            }
            infoNode={msg("emailInstruction")}
        />
    );
});

export default LoginResetPassword;